import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const MenuButton = (props: IIconProps) => {
  const { width = 24, height = 24, className, onClick } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12h18M3 6h18M3 18h18"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(MenuButton);

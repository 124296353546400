import React, { useCallback, useState } from 'react';

import CloseIcon from '../../images/Close';
import FacebookIcon from '../../images/Facebook';
import Logo from '../../images/Header/Logo';
import Humburger from '../../images/Humburger';
import InstagramIcon from '../../images/Instagram';
import LinkedinIcon from '../../images/Linkedin';
// import TwitterIcon from "../../images/Twitter"
import SideNav from '../SideNav/SideNav';

import style from './Header.module.scss';

import cn from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

export interface IHeaderProps {
  className?: string;
  isDarkTheme?: boolean;
}

const Header = ({ className, isDarkTheme = false }: IHeaderProps) => {
  const [isSidebarVisible, setSidebarVisibility] = useState(false);

  const showSidebarMenu = () => {
    if (!isSidebarVisible) {
      setSidebarVisibility(true);
    }
  };

  const handleSidenavClose = useCallback(() => {
    if (isSidebarVisible) {
      setSidebarVisibility(false);
    }
  }, [isSidebarVisible]);

  return (
    <>
      <header className={cn(className, style.header, { [style.dark]: isDarkTheme })}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col">
              <Link to="/" className={style.logo}>
                <Logo />
              </Link>
            </div>
            <div className={cn('col-auto', style.links)}>
              <Link activeClassName={style.active} to="/company/">
                Company
              </Link>
              <Link activeClassName={style.active} to="/contact/">
                Contact
              </Link>
              <a href="https://trend-capital-holdings-inc.hirehive.com" target="_blank">
                Join Us
              </a>
              <Humburger
                className={cn('d-lg-none', style.hamburger)}
                onClick={showSidebarMenu}
                aria-label="Open Side Nav"
              />
            </div>
          </div>
        </div>
      </header>
      <SideNav isOpen={isSidebarVisible} onClose={handleSidenavClose}>
        <div className={style.buttonsSection}>
          <div className="row align-items-center">
            <div className="col">
              <Link to="/" className={style.logo}>
                <Logo />
              </Link>
            </div>
            <div className="col-auto">
              <button
                className={style.closeButton}
                onClick={handleSidenavClose}
                aria-label="Close Side Nav"
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        </div>
        <div className={style.sideNavLinks}>
          <Link activeClassName={style.active} to="/company/">
            Company
          </Link>
          <Link activeClassName={style.active} to="/contact/">
            Contact
          </Link>
          <a href="https://trend-capital-holdings-inc.hirehive.com" target="_blank">
            Join Us
          </a>
        </div>
        <div className={style.socialLinks}>
          <a href="https://www.facebook.com/trendcapital">
            <FacebookIcon />
          </a>
          {/* <a href="/">
            <TwitterIcon />
          </a> */}
          <a href="https://www.instagram.com/trendcapitalinc/">
            <InstagramIcon />
          </a>
          <a href="https://www.linkedin.com/company/trend-capital-holdings-inc-">
            <LinkedinIcon />
          </a>
        </div>
        <div className={style.legalLinks}>
          <Link activeClassName={style.active} to="/privacy-policy/">
            Privacy Policy
          </Link>
        </div>
      </SideNav>
    </>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  className: '',
  siteTitle: '',
};

export default Header;

import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const LogoIcon = (props: IIconProps) => {
  const { width = 48, height = 48, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.8 26.4V5.26a7.544 7.544 0 0 1-3.29.762 7.727 7.727 0 0 1-3.14-.661 22.228 22.228 0 0 0-9.848-1.45 22.325 22.325 0 0 0-10.525 3.638 725.297 725.297 0 0 0 2.964 3.078l-.302.331-4.27-2.239a23.265 23.265 0 0 0-4.02 4.173l4.146 4.325-.201.457-5.3-2.773a22.583 22.583 0 0 0-2.262 5.241l5.803 6.03v.508l-6.48-3.358c0 .051-.026.077-.026.128a22.916 22.916 0 0 0 .703 9.489l28.889 15.06v-9.234c0-6.488-4.22-11.957-10.023-13.79a11.821 11.821 0 0 1-.327-2.696c0-2.493.779-4.808 2.085-6.716.126.05.251.127.402.203a10.154 10.154 0 0 0-.452 3.028c0 1.45.301 2.824.829 4.07 4.37.357 8.038 3.155 9.671 7.073H40.8V26.4zm-2.613-8.242c-1.457 0-2.637-1.196-2.637-2.672 0-.203.025-.407.075-.61a7.527 7.527 0 0 1 3.869 2.9 2.316 2.316 0 0 1-1.307.382z"
        fill="#fff"
      />
    </svg>
  );
};

export default React.memo(LogoIcon);

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';

import Footer from './Footer/Footer';
import Header from './Header/Header';

import '../styles/global.scss';

import { graphql, useStaticQuery } from 'gatsby';

export interface IDefaultLayoutProps {
  children: any;
  headerClassName?: string;
  isDarkTheme?: boolean;
}

const Layout = ({ children, headerClassName, isDarkTheme }: IDefaultLayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        className={headerClassName}
        isDarkTheme={isDarkTheme}
      />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;

import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const InstagramIcon = (props: IIconProps) => {
  const { width = 24, height = 24, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 2H7a5 5 0 0 0-5 5v10a5 5 0 0 0 5 5h10a5 5 0 0 0 5-5V7a5 5 0 0 0-5-5z"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 11.37a4 4 0 1 1-7.913 1.174A4 4 0 0 1 16 11.37zM17.5 6.5h.01"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(InstagramIcon);

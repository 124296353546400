import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const LogoIcon = (props: IIconProps) => {
  const { width = 92, height = 20, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 92 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.43558H5.64417V19.9591H9.61145V3.43558H15.2965V0H0V3.43558ZM37.5462 19.9591H50.4296V16.6053H41.4725V11.4519H49.8979V8.30266H41.4725V3.35378H50.4296V0H37.5462V19.9591ZM66.8306 13.0061H66.9942V0H70.7569V19.9591H67.485L58.6915 6.87117H58.5279V19.9591H54.806V0H58.1189L66.8306 13.0061ZM83.0264 0H75.7462V19.9591H83.0264C89.0386 19.9591 91.9425 16.2372 91.9425 9.93865C91.9425 3.64008 89.0795 0 83.0264 0ZM82.4947 16.6053H79.6726V3.35378H82.4947C86.1756 3.35378 87.8934 5.60327 87.8934 10.0205C87.8934 14.4376 86.2165 16.6053 82.4947 16.6053ZM26.3806 0C30.7977 0 33.3744 2.49489 33.3744 6.33947C33.3744 9.32515 31.5339 11.2883 29.7343 11.9836L33.7425 20H29.3253L25.8489 12.5971H22.2906V19.9591H18.4051V9.52965H25.6853C27.8938 9.52965 29.2435 8.50716 29.2435 6.42127C29.2435 4.33538 27.8529 3.19018 25.7671 3.19018H18.4051V0H26.3806Z"
        fill="black"
      />
    </svg>
  );
};

export default React.memo(LogoIcon);

import React from 'react';

import FacebookIcon from '../../images/Facebook';
import LogoMini from '../../images/Header/LogoMini';
import InstagramIcon from '../../images/Instagram';
import LinkedinIcon from '../../images/Linkedin';
// import TwitterIcon from "../../images/Twitter"
import MailIcon from '../../images/Mail';
import MapPinIcon from '../../images/MapPin';

import style from './Footer.module.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className={style.topLinks}>
        <div className="container">
          <div className="row">
            <div className={cn('col-12 col-lg-8', style.left)}>
              <Link to="/company/">Company</Link>
              <Link to="/contact/">Contact</Link>
              <a href="https://trend-capital-holdings-inc.hirehive.com" target="_blank">
                Join Us
              </a>
            </div>
            <div className={cn('col-12 col-lg-4', style.right)}>
              <a href="https://www.facebook.com/trendcapital" target="_blank">
                <FacebookIcon />
              </a>
              {/* <a href="/" target="_blank">
                <TwitterIcon />
              </a> */}
              <a href="https://www.instagram.com/trendcapitalinc/" target="_blank">
                <InstagramIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/trend-capital-holdings-inc-"
                target="_blank"
              >
                <LinkedinIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={style.info}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-1">
              <LogoMini />
            </div>
            <div className="col-12 col-md-10 offset-md-1 col-lg-4 offset-lg-0">
              <p>
                Trend is a PNW-based fintech company that reaches millions of users and consumers
                daily.
              </p>
            </div>
            <div className={cn('col-12 col-md-6 col-lg-4', style.withIcon)}>
              <MapPinIcon />
              <p>
                655 W Columbia Way, #300 <br className="d-lg-none" />
                Vancouver WA 98660
              </p>
            </div>
            <div
              className={cn(
                'col-12 col-md-6 col-lg-3 justify-content-start justify-content-md-end justify-content-lg-start',
                style.withIcon
              )}
            >
              <MailIcon />
              <p>
                <a href="mailto:hello@trendcapital.com">hello@trendcapital.com</a>
              </p>
            </div>
            <div className={cn('col-12', style.bottomInfo)}>
              <p>
                © 2015–{new Date().getFullYear()} Trend Capital Holdings, Inc. All rights reserved.{' '}
                <Link to="/privacy-policy/">Privacy Policy</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
